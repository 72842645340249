import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/CategoryPageLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Campaigns Reference`}</h1>
    <p>{`Application, screens, and forms reference with detailed information about all the fields in Campaign screens.`}</p>
    <p>{`A campaign is a planned sequence of activities and messages that aim to achieve a specific goal, such as promoting a product, raising awareness, or engaging with an audience.`}</p>
    <p>{`You can find Campaigns in `}<strong parentName="p">{`Engagement Studio`}</strong>{` > AdCenter > Campaigns`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      